.convenience_full_width{
width: 100%;
height: auto;
}

.main_convenience_pic{
    background-image: url(./../../Assets/buildings.png);
    width: 100%;
    height: 26vw;
    background-size: cover;
gap: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.convenience_text{
    color: #0E0E10;
text-align: center;
font-family: Poppins;
font-size: 3vw;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.16px;
}

.below_convenience{
    color: #0E0E10;
text-align: center;
font-family: Poppins;
font-size: 1.7vw;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.68px;
}

.ofr_btn{
    width: 12vw;
height: 2.8vw;
flex-shrink: 0;
border-radius: 0.7vw;
border: 1px solid #D5AF05;
background: linear-gradient(180deg, #FFF500 0%, #CCA006 100%);
box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
color: #000;
font-family: Poppins;
font-size: 0.8vw;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.7px;
box-shadow: 0px 2px #FFF500 ;
}

.call_text{
    color: #FFF;
text-align: center;

font-family: Poppins;
font-size: 1.7vw;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.68px;
}
.main_steps_width {
  width: 100%;
  height: auto;
  margin-top: 3vw;
}

.steps_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.steps_heading {
  color: #0e0e10;
  font-family: Poppins;
  font-size: 3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 3vw;
}

.steps_flex_row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.step_col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30vw;
  gap: 1vw;
}

.circles {
  width: 7vw;
}

.three_steps_headings {
  color: #0e0e10;
  font-family: Poppins;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
}

.below_headings {
  color: #5f605d;
  text-align: center;
  font-family: Poppins;
  font-size: 1vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.9px;
  padding: 0px 1vw;
}

@media only screen and (max-width: 600px) {
  .steps_flex_row {
    flex-direction: column;
    gap: 3vw !important;
  }
  .steps_heading {
    color: #0e0e10;
    font-family: Poppins;
    font-size: 5vw;
  }

  .circles {
    width: 12vw;
  }
  .step_col {
    gap: 2vw !important;
    width: 60vw !important;
  }
  .three_steps_headings {
    color: #0e0e10;
    font-family: Poppins;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
  }

  .below_headings {
    color: #5f605d;
    text-align: center;
    font-family: Poppins;
    font-size: 2vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.9px;
    padding: 0px 1vw;
  }
}
